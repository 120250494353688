import Vue from "vue";
import App from "./App.vue";
import store from "./services/store.js";
import router from "./services/router";
import Request from "./services/axios.js";
import VueCookies from "vue-cookies";
import VueHtmlToPaper from "vue-html-to-paper";
import i18n from "./services/i18n";
import ComponentsInit from "@/components/shared/index.js";
import vuetify from "@/plugins/vuetify";
import VuetifyMoney from "./plugins/vuetify-money";
import mixin from "./mixin";
import VueToastr from "vue-toastr";


// import "@/assets/css/tailwind.css";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/css/scss/index.scss";

import loader from "vue-ui-preloader";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: ["css/print.css"],
};

// set global component
Vue.use(loader);
Vue.mixin(mixin);
Vue.use(ComponentsInit);
Vue.use(VueCookies);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueToastr, { defaultTimeout: 500 });
import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

Vue.config.productionTip = false;
Vue.prototype.$request = new Request();

import { io } from "socket.io-client";

//set global socket
Vue.prototype.$connectSocket = (
  rolee = Vue.$cookies.get("user_role"),
  
  token = Vue.$cookies.get("token"),
  channel_type = Vue.$cookies.get("channel-type")
) => {
  if (rolee) {
    
    let socket;
    if (parseInt(channel_type) === 1) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch1`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }

      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    if (parseInt(channel_type) === 2) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch2`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    if (parseInt(channel_type) === 3) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch3`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    if (parseInt(channel_type) === 4) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch4`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    // KLAKLOUK
    if (parseInt(channel_type) === 5) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}klaklouk-ch5`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //BACCARAT CHANNEL 1
    if (parseInt(channel_type) === 6) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch6`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //BACCARAT CHANNEL 2
    if (parseInt(channel_type) === 7) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch7`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //BACCARAT CHANNEL 18
    if (parseInt(channel_type) === 18) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch18`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //DRAGON CHANNEL 1
    if (parseInt(channel_type) === 8) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch8`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //DRAGON CHANNEL 2
    if (parseInt(channel_type) === 9) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch9`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //DRAGON CHANNEL 2
    if (parseInt(channel_type) === 19) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch19`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //LOTTO
    if (parseInt(channel_type) === 10) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-ch10`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //YUKI
    if (parseInt(channel_type) === 11) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}yuki-ch11`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //APONG
    if (parseInt(channel_type) === 12) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}abong-ch12`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //Fantan
    if (parseInt(channel_type) === 13) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}fantan-ch13`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //Taisiev
    if (parseInt(channel_type) === 14) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}taisiev-ch14`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //cock
    if (parseInt(channel_type) === 15) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch15`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //cock
    if (parseInt(channel_type) === 16) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch16`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }
    //apong
    if (parseInt(channel_type) === 17) {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}abong-ch17`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }

    //Lotto Slotmachine
    if (rolee == "611532b19c6e77ad0fd50c1c") {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-lotto`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }

    //Yuki Slotmachine
    if (rolee == "611532b19c6e77ad0fd50c1c") {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-yuki`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }

    //Lotto PP78 Slotmachine
    if (rolee == "611532b19c6e77ad0fd50c1c") {
      if (token) {
        socket = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-pp78`, {
          extraHeaders: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });
      }
      socket.on("connect", () => {
        console.log("socket: Connectd");
      });

      socket.on("connect_error", (err) => {
        console.log("socket: Message err == ", err);
      });

      socket.on("disconnect", () => {
        console.log(`socket: Disconnection`);
      });

      socket.on("error", (err) => {
        console.log("socket: Error connecting to server", err);
      });

      socket.on("reconnect", (number) => {
        console.log("socket: Reconnected to server", number);
      });

      socket.on("reconnect_attempt", () => {
        console.log("socket: Reconnect Attempt", "alert problem");
      });

      socket.on("reconnecting", (number) => {
        console.log("socket: Reconnecting to server", number);
      });
      socket.on("reconnect_error", (err) => {
        console.log("socket: Reconnect Error", err);
      });
      socket.on("reconnect_failed", () => {
        console.log("socket: Reconnect failed", "offline");
      });

      Vue.prototype.$socket = socket;
    }


    //***//
    let socket1;
    if (token) {
      socket1 = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch2`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket1.on("connect", () => {
        console.log("socket1: Connectd");
      });

      socket1.on("connect_error", (err) => {
        console.log("socket1: Message err == ", err);
      });

      socket1.on("disconnect", () => {
        console.log(`socket1: Disconnection`);
      });

      socket1.on("error", (err) => {
        console.log("socket1: Error connecting to server", err);
      });

      socket1.on("reconnect", (number) => {
        console.log("socket1: Reconnected to server", number);
      });

      socket1.on("reconnect_attempt", () => {
        console.log("socket1: Reconnect Attempt", "alert problem");
      });

      socket1.on("reconnecting", (number) => {
        console.log("socket1: Reconnecting to server", number);
      });
      socket1.on("reconnect_error", (err) => {
        console.log("socket1: Reconnect Error", err);
      });
      socket1.on("reconnect_failed", () => {
        console.log("socket1: Reconnect failed", "offline");
      });

      Vue.prototype.$socket1 = socket1;
    }
    let socket2;
    if (token) {
      socket2 = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch2`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket2.on("connect", () => {
        console.log("socket2: Connectd");
      });

      socket2.on("connect_error", (err) => {
        console.log("socket2: Message err == ", err);
      });

      socket2.on("disconnect", () => {
        console.log(`socket2: Disconnection`);
      });

      socket2.on("error", (err) => {
        console.log("socket2: Error connecting to server", err);
      });

      socket2.on("reconnect", (number) => {
        console.log("socket2: Reconnected to server", number);
      });

      socket2.on("reconnect_attempt", () => {
        console.log("socket2: Reconnect Attempt", "alert problem");
      });

      socket2.on("reconnecting", (number) => {
        console.log("socket2: Reconnecting to server", number);
      });
      socket2.on("reconnect_error", (err) => {
        console.log("socket2: Reconnect Error", err);
      });
      socket2.on("reconnect_failed", () => {
        console.log("socket2: Reconnect failed", "offline");
      });

      Vue.prototype.$socket2 = socket2;
    }
    let socket3;
    if (token) {
        socket3 = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch3`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket3.on("connect", () => {
        console.log("socket3: Connectd");
      });

      socket3.on("connect_error", (err) => {
        console.log("socket3: Message err == ", err);
      });

      socket3.on("disconnect", () => {
        console.log(`socket3: Disconnection`);
      });

      socket3.on("error", (err) => {
        console.log("socket3: Error connecting to server", err);
      });

      socket3.on("reconnect", (number) => {
        console.log("socket3: Reconnected to server", number);
      });

      socket3.on("reconnect_attempt", () => {
        console.log("socket3: Reconnect Attempt", "alert problem");
      });

      socket3.on("reconnecting", (number) => {
        console.log("socket3: Reconnecting to server", number);
      });
      socket3.on("reconnect_error", (err) => {
        console.log("socket3: Reconnect Error", err);
      });
      socket3.on("reconnect_failed", () => {
        console.log("socket3: Reconnect failed", "offline");
      });

      Vue.prototype.$socket3 = socket3;
    }
    let socket4;
    if (token) {
        socket4 = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch4`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket4.on("connect", () => {
        console.log("socket4: Connectd");
      });

      socket4.on("connect_error", (err) => {
        console.log("socket4: Message err == ", err);
      });

      socket4.on("disconnect", () => {
        console.log(`socket4: Disconnection`);
      });

      socket4.on("error", (err) => {
        console.log("socket4: Error connecting to server", err);
      });

      socket4.on("reconnect", (number) => {
        console.log("socket4: Reconnected to server", number);
      });

      socket4.on("reconnect_attempt", () => {
        console.log("socket4: Reconnect Attempt", "alert problem");
      });

      socket4.on("reconnecting", (number) => {
        console.log("socket4: Reconnecting to server", number);
      });
      socket4.on("reconnect_error", (err) => {
        console.log("socket4: Reconnect Error", err);
      });
      socket4.on("reconnect_failed", () => {
        console.log("socket4: Reconnect failed", "offline");
      });

      Vue.prototype.$socket4 = socket4;
    }
    let socket5;//KLAKLOUK
    if (token) {
      socket5 = io(`${process.env.VUE_APP_WEBSOCKET_URL}klaklouk-ch5`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket5.on("connect", () => {
        console.log("socket5: Connectd");
      });

      socket5.on("connect_error", (err) => {
        console.log("socket5: Message err == ", err);
      });

      socket5.on("disconnect", () => {
        console.log(`socket5: Disconnection`);
      });

      socket5.on("error", (err) => {
        console.log("socket5: Error connecting to server", err);
      });

      socket5.on("reconnect", (number) => {
        console.log("socket5: Reconnected to server", number);
      });

      socket5.on("reconnect_attempt", () => {
        console.log("socket5: Reconnect Attempt", "alert problem");
      });

      socket5.on("reconnecting", (number) => {
        console.log("socket5: Reconnecting to server", number);
      });
      socket5.on("reconnect_error", (err) => {
        console.log("socket5: Reconnect Error", err);
      });
      socket5.on("reconnect_failed", () => {
        console.log("socket5: Reconnect failed", "offline");
      });

      Vue.prototype.$socket5 = socket5;
    }
    let socket6;//BACCARAT CHANNEL 1
    if (token) {
      socket6 = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch6`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket6.on("connect", () => {
        console.log("socket6: Connectd");
      });

      socket6.on("connect_error", (err) => {
        console.log("socket6: Message err == ", err);
      });

      socket6.on("disconnect", () => {
        console.log(`socket5: Disconnection`);
      });

      socket6.on("error", (err) => {
        console.log("socket6: Error connecting to server", err);
      });

      socket6.on("reconnect", (number) => {
        console.log("socket6: Reconnected to server", number);
      });

      socket6.on("reconnect_attempt", () => {
        console.log("socket6: Reconnect Attempt", "alert problem");
      });

      socket6.on("reconnecting", (number) => {
        console.log("socket6: Reconnecting to server", number);
      });
      socket6.on("reconnect_error", (err) => {
        console.log("socket6: Reconnect Error", err);
      });
      socket6.on("reconnect_failed", () => {
        console.log("socket6: Reconnect failed", "offline");
      });

      Vue.prototype.$socket6= socket6;
    }
    let socket7;//BACCARAT CHANNEL 2
    if (token) {
      socket7 = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch7`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket7.on("connect", () => {
        console.log("socket7: Connectd");
      });

      socket7.on("connect_error", (err) => {
        console.log("socket7: Message err == ", err);
      });

      socket7.on("disconnect", () => {
        console.log(`socket7: Disconnection`);
      });

      socket7.on("error", (err) => {
        console.log("socket7: Error connecting to server", err);
      });

      socket7.on("reconnect", (number) => {
        console.log("socket7: Reconnected to server", number);
      });

      socket7.on("reconnect_attempt", () => {
        console.log("socket7: Reconnect Attempt", "alert problem");
      });

      socket7.on("reconnecting", (number) => {
        console.log("socket7: Reconnecting to server", number);
      });
      socket7.on("reconnect_error", (err) => {
        console.log("socket7: Reconnect Error", err);
      });
      socket7.on("reconnect_failed", () => {
        console.log("socket7: Reconnect failed", "offline");
      });

      Vue.prototype.$socket7 = socket7;
    }
    let socket18;//BACCARAT CHANNEL 18
    if (token) {
      socket18 = io(`${process.env.VUE_APP_WEBSOCKET_URL}baccarat-ch18`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket18.on("connect", () => {
        console.log("socket18: Connectd");
      });

      socket18.on("connect_error", (err) => {
        console.log("socket18: Message err == ", err);
      });

      socket18.on("disconnect", () => {
        console.log(`socket18: Disconnection`);
      });

      socket18.on("error", (err) => {
        console.log("socket18: Error connecting to server", err);
      });

      socket18.on("reconnect", (number) => {
        console.log("socket18: Reconnected to server", number);
      });

      socket18.on("reconnect_attempt", () => {
        console.log("socket18: Reconnect Attempt", "alert problem");
      });

      socket18.on("reconnecting", (number) => {
        console.log("socket18: Reconnecting to server", number);
      });
      socket18.on("reconnect_error", (err) => {
        console.log("socket18: Reconnect Error", err);
      });
      socket18.on("reconnect_failed", () => {
        console.log("socket18: Reconnect failed", "offline");
      });

      Vue.prototype.$socket18 = socket18;
    }
    let socket8;//DRAGON CHANNEL 1
    if (token) {
      socket8 = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch8`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket8.on("connect", () => {
        console.log("socket8: Connectd");
      });

      socket8.on("connect_error", (err) => {
        console.log("socket8: Message err == ", err);
      });

      socket8.on("disconnect", () => {
        console.log(`socket8: Disconnection`);
      });

      socket8.on("error", (err) => {
        console.log("socket8: Error connecting to server", err);
      });

      socket8.on("reconnect", (number) => {
        console.log("socket8: Reconnected to server", number);
      });

      socket8.on("reconnect_attempt", () => {
        console.log("socket8: Reconnect Attempt", "alert problem");
      });

      socket8.on("reconnecting", (number) => {
        console.log("socket8: Reconnecting to server", number);
      });
      socket8.on("reconnect_error", (err) => {
        console.log("socket8: Reconnect Error", err);
      });
      socket8.on("reconnect_failed", () => {
        console.log("socket8: Reconnect failed", "offline");
      });

      Vue.prototype.$socket8 = socket8;
    }
    let socket9;//DRAGON CHANNEL 2
    if (token) {
      socket9 = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch9`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket9.on("connect", () => {
        console.log("socket9: Connectd");
      });

      socket9.on("connect_error", (err) => {
        console.log("socket9: Message err == ", err);
      });

      socket9.on("disconnect", () => {
        console.log(`socket9: Disconnection`);
      });

      socket9.on("error", (err) => {
        console.log("socket9: Error connecting to server", err);
      });

      socket9.on("reconnect", (number) => {
        console.log("socket9: Reconnected to server", number);
      });

      socket9.on("reconnect_attempt", () => {
        console.log("socket9: Reconnect Attempt", "alert problem");
      });

      socket9.on("reconnecting", (number) => {
        console.log("socket9: Reconnecting to server", number);
      });
      socket9.on("reconnect_error", (err) => {
        console.log("socket9: Reconnect Error", err);
      });
      socket9.on("reconnect_failed", () => {
        console.log("socket9: Reconnect failed", "offline");
      });

      Vue.prototype.$socket9 = socket9;
    }
    let socket19;//DRAGON CHANNEL 2
    if (token) {
      socket19 = io(`${process.env.VUE_APP_WEBSOCKET_URL}dragon-ch19`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket19.on("connect", () => {
        console.log("socket19: Connectd");
      });

      socket19.on("connect_error", (err) => {
        console.log("socket19: Message err == ", err);
      });

      socket19.on("disconnect", () => {
        console.log(`socket19: Disconnection`);
      });

      socket19.on("error", (err) => {
        console.log("socket19: Error connecting to server", err);
      });

      socket19.on("reconnect", (number) => {
        console.log("socket19: Reconnected to server", number);
      });

      socket19.on("reconnect_attempt", () => {
        console.log("socket19: Reconnect Attempt", "alert problem");
      });

      socket19.on("reconnecting", (number) => {
        console.log("socket19: Reconnecting to server", number);
      });
      socket19.on("reconnect_error", (err) => {
        console.log("socket19: Reconnect Error", err);
      });
      socket19.on("reconnect_failed", () => {
        console.log("socket19: Reconnect failed", "offline");
      });

      Vue.prototype.$socket19 = socket19;
    }
    let socket10;//LOTTO
    if (token) {
      socket10 = io(`${process.env.VUE_APP_WEBSOCKET_URL}lotto-ch10`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket10.on("connect", () => {
        console.log("socket10: Connectd");
      });

      socket10.on("connect_error", (err) => {
        console.log("socket10: Message err == ", err);
      });

      socket10.on("disconnect", () => {
        console.log(`socket10: Disconnection`);
      });

      socket10.on("error", (err) => {
        console.log("socket10: Error connecting to server", err);
      });

      socket10.on("reconnect", (number) => {
        console.log("socket10: Reconnected to server", number);
      });

      socket10.on("reconnect_attempt", () => {
        console.log("socket10: Reconnect Attempt", "alert problem");
      });

      socket10.on("reconnecting", (number) => {
        console.log("socket10: Reconnecting to server", number);
      });
      socket10.on("reconnect_error", (err) => {
        console.log("socket10: Reconnect Error", err);
      });
      socket10.on("reconnect_failed", () => {
        console.log("socket10: Reconnect failed", "offline");
      });

      Vue.prototype.$socket10 = socket10;
    }
    let socket11;//YUKI
    if (token) {
      socket11 = io(`${process.env.VUE_APP_WEBSOCKET_URL}yuki-ch11`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket11.on("connect", () => {
        console.log("socket11: Connectd");
      });

      socket11.on("connect_error", (err) => {
        console.log("socket11: Message err == ", err);
      });

      socket11.on("disconnect", () => {
        console.log(`socket11: Disconnection`);
      });

      socket11.on("error", (err) => {
        console.log("socket11: Error connecting to server", err);
      });

      socket11.on("reconnect", (number) => {
        console.log("socket11: Reconnected to server", number);
      });

      socket11.on("reconnect_attempt", () => {
        console.log("socket11: Reconnect Attempt", "alert problem");
      });

      socket11.on("reconnecting", (number) => {
        console.log("socket11: Reconnecting to server", number);
      });
      socket11.on("reconnect_error", (err) => {
        console.log("socket11: Reconnect Error", err);
      });
      socket11.on("reconnect_failed", () => {
        console.log("socket11: Reconnect failed", "offline");
      });

      Vue.prototype.$socket11 = socket11;
    }
    let socket12;//APONG
    if (token) {
      socket12 = io(`${process.env.VUE_APP_WEBSOCKET_URL}abong-ch12`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket12.on("connect", () => {
        console.log("socket12: Connectd");
      });

      socket12.on("connect_error", (err) => {
        console.log("socket12: Message err == ", err);
      });

      socket12.on("disconnect", () => {
        console.log(`socket12: Disconnection`);
      });

      socket12.on("error", (err) => {
        console.log("socket12: Error connecting to server", err);
      });

      socket12.on("reconnect", (number) => {
        console.log("socket12: Reconnected to server", number);
      });

      socket12.on("reconnect_attempt", () => {
        console.log("socket12: Reconnect Attempt", "alert problem");
      });

      socket12.on("reconnecting", (number) => {
        console.log("socket12: Reconnecting to server", number);
      });
      socket12.on("reconnect_error", (err) => {
        console.log("socket12: Reconnect Error", err);
      });
      socket12.on("reconnect_failed", () => {
        console.log("socket12: Reconnect failed", "offline");
      });

      Vue.prototype.$socket12 = socket12;
    }
    let socket13;//Fantan
    if (token) {
      socket13 = io(`${process.env.VUE_APP_WEBSOCKET_URL}fantan-ch13`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket13.on("connect", () => {
        console.log("socket13: Connectd");
      });

      socket13.on("connect_error", (err) => {
        console.log("socket13: Message err == ", err);
      });

      socket13.on("disconnect", () => {
        console.log(`socket13: Disconnection`);
      });

      socket13.on("error", (err) => {
        console.log("socket13: Error connecting to server", err);
      });

      socket13.on("reconnect", (number) => {
        console.log("socket13: Reconnected to server", number);
      });

      socket13.on("reconnect_attempt", () => {
        console.log("socket13: Reconnect Attempt", "alert problem");
      });

      socket13.on("reconnecting", (number) => {
        console.log("socket13: Reconnecting to server", number);
      });
      socket13.on("reconnect_error", (err) => {
        console.log("socket13: Reconnect Error", err);
      });
      socket13.on("reconnect_failed", () => {
        console.log("socket13: Reconnect failed", "offline");
      });

      Vue.prototype.$socket13 = socket13;
    }
    let socket14;//Taisiev
    if (token) {
      socket14 = io(`${process.env.VUE_APP_WEBSOCKET_URL}taisiev-ch14`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket14.on("connect", () => {
        console.log("socket14: Connectd");
      });

      socket14.on("connect_error", (err) => {
        console.log("socket14: Message err == ", err);
      });

      socket14.on("disconnect", () => {
        console.log(`socket14: Disconnection`);
      });

      socket14.on("error", (err) => {
        console.log("socket14: Error connecting to server", err);
      });

      socket14.on("reconnect", (number) => {
        console.log("socket14: Reconnected to server", number);
      });

      socket14.on("reconnect_attempt", () => {
        console.log("socket14: Reconnect Attempt", "alert problem");
      });

      socket14.on("reconnecting", (number) => {
        console.log("socket14: Reconnecting to server", number);
      });
      socket14.on("reconnect_error", (err) => {
        console.log("socket14: Reconnect Error", err);
      });
      socket14.on("reconnect_failed", () => {
        console.log("socket14: Reconnect failed", "offline");
      });

      Vue.prototype.$socket14 = socket14;
    }
    let socket15;//Taisiev
    if (token) {
      socket15 = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch15`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket15.on("connect", () => {
        console.log("socket15: Connectd");
      });

      socket15.on("connect_error", (err) => {
        console.log("socket15: Message err == ", err);
      });

      socket15.on("disconnect", () => {
        console.log(`socket15: Disconnection`);
      });

      socket15.on("error", (err) => {
        console.log("socket15: Error connecting to server", err);
      });

      socket15.on("reconnect", (number) => {
        console.log("socket15: Reconnected to server", number);
      });

      socket15.on("reconnect_attempt", () => {
        console.log("socket15: Reconnect Attempt", "alert problem");
      });

      socket15.on("reconnecting", (number) => {
        console.log("socket15: Reconnecting to server", number);
      });
      socket15.on("reconnect_error", (err) => {
        console.log("socket15: Reconnect Error", err);
      });
      socket15.on("reconnect_failed", () => {
        console.log("socket15: Reconnect failed", "offline");
      });

      Vue.prototype.$socket15 = socket15;
    }
    let socket16;//cock
    if (token) {
      socket16 = io(`${process.env.VUE_APP_WEBSOCKET_URL}cock-ch16`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket16.on("connect", () => {
        console.log("socket16: Connectd");
      });

      socket16.on("connect_error", (err) => {
        console.log("socket16: Message err == ", err);
      });

      socket16.on("disconnect", () => {
        console.log(`socket16: Disconnection`);
      });

      socket16.on("error", (err) => {
        console.log("socket16: Error connecting to server", err);
      });

      socket16.on("reconnect", (number) => {
        console.log("socket16: Reconnected to server", number);
      });
      socket16.on("reconnect_attempt", () => {
        console.log("socket16: Reconnect Attempt", "alert problem");
      });
      socket16.on("reconnecting", (number) => {
        console.log("socket16: Reconnecting to server", number);
      });
      socket16.on("reconnect_error", (err) => {
        console.log("socket16: Reconnect Error", err);
      });
      socket16.on("reconnect_failed", () => {
        console.log("socket16: Reconnect failed", "offline");
      });

      Vue.prototype.$socket16 = socket16;
    }
    let socket17;//Taisiev
    if (token) {
      socket17 = io(`${process.env.VUE_APP_WEBSOCKET_URL}abong-ch17`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket17.on("connect", () => {
        console.log("socket17: Connectd");
      });

      socket17.on("connect_error", (err) => {
        console.log("socket17: Message err == ", err);
      });

      socket17.on("disconnect", () => {
        console.log(`socket17: Disconnection`);
      });
      socket17.on("error", (err) => {
        console.log("socket17: Error connecting to server", err);
      });
      socket17.on("reconnect", (number) => {
        console.log("socket17: Reconnected to server", number);
      });
      socket17.on("reconnect_attempt", () => {
        console.log("socket17: Reconnect Attempt", "alert problem");
      });
      socket17.on("reconnecting", (number) => {
        console.log("socket17: Reconnecting to server", number);
      });
      socket17.on("reconnect_error", (err) => {
        console.log("socket17: Reconnect Error", err);
      });
      socket17.on("reconnect_failed", () => {
        console.log("socket17: Reconnect failed", "offline");
      });

      Vue.prototype.$socket17 = socket17;
    }

    let socket90;//Lotto Slotmichine
    if (token) {
      socket90 = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-lotto`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket90.on("connect", () => {
        console.log("socket90: Connectd");
      });

      socket90.on("connect_error", (err) => {
        console.log("socket90: Message err == ", err);
      });

      socket90.on("disconnect", () => {
        console.log(`socket90: Disconnection`);
      });
      socket90.on("error", (err) => {
        console.log("socket90: Error connecting to server", err);
      });
      socket90.on("reconnect", (number) => {
        console.log("socket90: Reconnected to server", number);
      });
      socket90.on("reconnect_attempt", () => {
        console.log("socket90: Reconnect Attempt", "alert problem");
      });
      socket90.on("reconnecting", (number) => {
        console.log("socket90: Reconnecting to server", number);
      });
      socket90.on("reconnect_error", (err) => {
        console.log("socket90: Reconnect Error", err);
      });
      socket90.on("reconnect_failed", () => {
        console.log("socket90: Reconnect failed", "offline");
      });

      Vue.prototype.$socket90 = socket90;
    }

    let socket91;//Yuki Slotmichine
    if (token) {
      socket91 = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-yuki`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket91.on("connect", () => {
        console.log("socket91: Connectd");
      });

      socket91.on("connect_error", (err) => {
        console.log("socket91: Message err == ", err);
      });

      socket91.on("disconnect", () => {
        console.log(`socket91: Disconnection`);
      });
      socket91.on("error", (err) => {
        console.log("socket91: Error connecting to server", err);
      });
      socket91.on("reconnect", (number) => {
        console.log("socket91: Reconnected to server", number);
      });
      socket91.on("reconnect_attempt", () => {
        console.log("socket91: Reconnect Attempt", "alert problem");
      });
      socket91.on("reconnecting", (number) => {
        console.log("socket91: Reconnecting to server", number);
      });
      socket91.on("reconnect_error", (err) => {
        console.log("socket91: Reconnect Error", err);
      });
      socket91.on("reconnect_failed", () => {
        console.log("socket91: Reconnect failed", "offline");
      });

      Vue.prototype.$socket91 = socket91;
    }

    let socket92;//Lotto PP78 Slotmichine
    if (token) {
      socket92 = io(`${process.env.VUE_APP_WEBSOCKET_URL}slot-pp78`, {
        extraHeaders: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      socket92.on("connect", () => {
        console.log("socket92: Connectd");
      });

      socket92.on("connect_error", (err) => {
        console.log("socket92: Message err == ", err);
      });

      socket92.on("disconnect", () => {
        console.log(`socket92: Disconnection`);
      });
      socket92.on("error", (err) => {
        console.log("socket92: Error connecting to server", err);
      });
      socket92.on("reconnect", (number) => {
        console.log("socket92: Reconnected to server", number);
      });
      socket92.on("reconnect_attempt", () => {
        console.log("socket92: Reconnect Attempt", "alert problem");
      });
      socket92.on("reconnecting", (number) => {
        console.log("socket92: Reconnecting to server", number);
      });
      socket92.on("reconnect_error", (err) => {
        console.log("socket92: Reconnect Error", err);
      });
      socket92.on("reconnect_failed", () => {
        console.log("socket92: Reconnect failed", "offline");
      });

      Vue.prototype.$socket92 = socket92;
    }
  }
};

new Vue({
  store,
  router,
  i18n,
  vuetify,
  VuetifyMoney,
  render: (h) => h(App),
}).$mount("#app");
