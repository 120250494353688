import { render, staticRenderFns } from "./formChannel.vue?vue&type=template&id=8bbef2c2&scoped=true&"
import script from "./formChannel.vue?vue&type=script&lang=js&"
export * from "./formChannel.vue?vue&type=script&lang=js&"
import style0 from "./formChannel.vue?vue&type=style&index=0&id=8bbef2c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bbef2c2",
  null
  
)

export default component.exports