/* eslint-disable no-unused-vars */
import Request from '@/services/axios'

let request = new Request;

const fetchCurrentChannel = async ({ commit }, data) => {
    const response = await request.get({
        url: 'ch18/getCurrentChannelAndResult',
        data
    });
    if (response.data.code) {
        // console.log("fetchCurrentChannel", response.data.data)
        commit('UPDATE_KDPAYOUT', response.data.data.objKdPayout);
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
        commit('RESULT_TABLE', response.data.data.objCoResult);
    }
};



const createResult = async ({ commit }, { vm, data }) => {
    commit('LOADINGRESULT', true);
    const response = await request.post({
        url: 'ch18/createResultAndNewChannel',
        data
    });
    if (response.data.code) {
        // console.log("createResult", response.data.data)
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', response.data.data.objSummaryCoResult);
        commit('RESULT_TABLE', response.data.data.objCoResult);
        commit('LOADINGRESULT', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
        // window.location.reload();
    }

};

const UpdateChanelStatus = async ({ commit }, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    const response = await request.put({
        url: 'ch18/updateStatusChannel',
        data
    });
    if (response.data.code) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const updateFixedPayout = async ({commit}, { vm, data }) => {
    // commit('LOADINGRESULT', true);
    // console.log('updateFixedPayout', data)
    const response = await request.post({
        url: 'coChannel/updateFixedPayout',
        data
    });
    if (response.data.code) {
        // commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const fetchBtnResult = async ({ commit }, data) => {
    const response = await request.get({
        url: 'ch18/getCoTypeOfBetting',
        data
    });
    if (response.data.code) {
        commit('BTN_RESULT', response.data.data.objCoTypeOfBetting);
    }
};
const updateChannel = async ({ commit }, { vm, data }) => {
    commit('LOADING', true);
    const response = await request.put({
        url: 'ch18',
        data
    });
    if (response.data) {
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('LOADING', false);
        vm.$toastr.s(`${response.data.message.descriptions}`)
    } else {
        vm.$toastr.e(`${response.data.message.descriptions}`)
    }
};

const resetResult = async ({commit}) => {
   
    const response = await request.get({
        url: 'ch18/resetResult'
    });
    if (response.data.code) {
        let objSummaryCoResult = {
            MERON: 0,
            WALA: 0,
            TIE: 0,
            CANCEL: 0
        }
        commit('UPDATE_CHANNEL', response.data.data.objCoChannel);
        commit('SUMMARY_RESULT', objSummaryCoResult);
        commit('RESULT_TABLE', []);

    }
};

export default {
    fetchCurrentChannel,
    fetchBtnResult,
    createResult,
    updateChannel,
    UpdateChanelStatus,
    resetResult,
    updateFixedPayout
};