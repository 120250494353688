<template>
<div>
  <v-data-table
    :headers="headers"
    :items="getViewTransaction ? getViewTransaction.object : []"
    :server-items-length="getViewTransaction.objectCount "
    :sort-desc.sync="data.descendingFormat"
    :sort-by.sync="data.sortByFormat"
    :footer-props="footerProps"
    :items-per-page.sync="data.rowsPerPage"
    :page.sync="data.page"
    :loading="!getViewTransaction.object"
    class="elevation-1"
    @update:sort-desc="getData"
    @update:page="getData"
    @update:items-per-page="getData"
    item-key="codeName"
    style="font-family: 'khmer mef1'"
  >
    <template v-slot:top>
        <v-row>
     <v-col cols="12" sm="12" md="4" class="text-left mt-4">
     <v-toolbar-title class="pl-5">{{$t('title.depositStatement')}}</v-toolbar-title>
    <v-spacer></v-spacer>  
    </v-col>        
    <v-col cols="6" sm="6" md="2" class="text-right pl-5">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pl-5"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.startDate"
            :label="$t('input.startDate')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          v-model="data.startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="6"
      sm="6"
      md="2"
      class="text-right pl-5 "
    >
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="data.endDate"
        :label="$t('input.endDate')"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
      <v-date-picker
        v-model="data.endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="8" sm="8" md="2" class="text-right pl-5">
      <v-text-field
        v-model="data.search"
        append-icon="mdi-magnify"
        :label="$t('input.search')"
        single-line
        hide-details
        clearable
         class="pl-5"
      />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" sm="4" md="2" class="text-right pr-5 mt-3">
      <v-btn 
        class="white--text btn-custom mr-5"
        color="green darken-1"
        depressed
        @click="_search()"
      >
       {{$t('input.search')}}
      </v-btn>
    </v-col>
  </v-row>
    </template>
    
     <template v-slot:[`item.no`]="{ item }">
         {{ (getViewTransaction.object.indexOf(item)  + ( (data.page -1 ) *  data.rowsPerPage))+ 1 }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
         {{ user.currency_type == 1 || user.currency_type == 3 ? currencyFormatEn(item.amount) : user.currency_type == 2 ?  currencyFormatKm(item.amount) : currencyFormatKm(item.amount) }}
      </template>
      <template v-slot:[`item.old_balance`]="{ item }">
         {{ user.currency_type == 1 || user.currency_type == 3 ? currencyFormatEn(item.old_balance) : user.currency_type == 2 ?  currencyFormatKm(item.old_balance) : currencyFormatKm(item.old_balance)  }}
      </template>
      <template v-slot:[`item.new_balance`]="{ item }">
         {{ user.currency_type == 1 || user.currency_type == 3 ? currencyFormatEn(item.new_balance) : user.currency_type == 2 ?  currencyFormatKm(item.new_balance) : currencyFormatKm(item.new_balance)  }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
         {{ item.date }}
      </template>
       <template slot="body.append">
        <tr style="background-color: #faffe5; font-weight: bold;">
          <td :colspan="4" class="text-xs-center pl-4 py-2 ">
            {{ $t("title.total")  }} 
          </td>
          <td v-if="user.role_id=='61829dc7e64b1b17688326d5'|| user.role_id=='61829dd1e64b1b17688326df' || user.role_id=='6472bf9b1ba4b8a1604940c1' || user.role_id=='6191dce94825e65937fa2340'|| user.role_id=='6191dc644825e6f57ffa231f' || user.role_id=='6472bfce1ba4b8a1604940c2'"></td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getViewTransaction.objAmount && ( user.currency_type == 1 || user.currency_type == 3 )
                ? currencyFormatEn(getViewTransaction.objAmount)
                :  getViewTransaction.objAmount && user.currency_type == 2 ?  currencyFormatKm(getViewTransaction.objAmount) :  currencyFormatKm(getViewTransaction.objAmount)
            }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getViewTransaction.objOldBalance && ( user.currency_type == 1 || user.currency_type == 3 )
                ? currencyFormatEn(getViewTransaction.objOldBalance)
                :  getViewTransaction.objOldBalance && user.currency_type == 2 ?  currencyFormatKm(getViewTransaction.objOldBalance) :  currencyFormatKm(getViewTransaction.objOldBalance)
            }}
          </td>
          <td class="text-xs-center total-style pl-4  py-2">
            {{
              getViewTransaction.objNewBalance && ( user.currency_type == 1 || user.currency_type == 3 )
                ? currencyFormatEn(getViewTransaction.objNewBalance)
                :  getViewTransaction.objNewBalance && user.currency_type == 2 ?  currencyFormatKm(getViewTransaction.objNewBalance) :  currencyFormatKm(getViewTransaction.objNewBalance)
            }}
          </td>
        </tr>
      </template>
  </v-data-table>
</div>

</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    
    search: null,
    data: {
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
      fields: ["user", "date", "amount", "old_balance", "new_balance"],
      search: "",
      startDate:  moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    },
     startDate:  moment().format("YYYY-MM-DD"),
     endDate:  moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      v_search:"",
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
  }),
  watch: {
    user: function () {
      this.$forceUpdate();
    },
  },
  computed: {
    headers() {
      if(this.user.role_id=='61829dc7e64b1b17688326d5' || this.user.role_id=='61829dd1e64b1b17688326df' || this.user.role_id=='6472bf9b1ba4b8a1604940c1' ||this.user.role_id=='6191dce94825e65937fa2340'||this.user.role_id=='6191dc644825e6f57ffa231f'||this.user.role_id=='6472bfce1ba4b8a1604940c2'){
        return [
          {
            text: this.$t("header.no"),
            align: "start",
            sortable: false,
            value: "no",
          },
          { text: this.$t("header.parentName"), value: "parent_name" },
          { text: this.$t("header.userName"), value: "user" },
          { text: this.$t("header.agentUserName"), value: "agent_username"},
          { text: this.$t("header.date"), value: "date" },
          { text: this.$t("header.withdrawAmount"), value: "amount" },
          {
            text: this.$t("header.oldBalance"),
            value: "old_balance",
            sortable: false,
          },
          { text: this.$t("header.newBalance"), value: "new_balance" },
        ];
      }else{
        return [
          {
            text: this.$t("header.no"),
            align: "start",
            sortable: false,
            value: "no",
          },
          { text: this.$t("header.parentName"), value: "parent_name" },
          { text: this.$t("header.userName"), value: "user" },
          { text: this.$t("header.date"), value: "date" },
          { text: this.$t("header.withdrawAmount"), value: "amount" },
          {
            text: this.$t("header.oldBalance"),
            value: "old_balance",
            sortable: false,
          },
          { text: this.$t("header.newBalance"), value: "new_balance" },
        ];
      }
    },
    ...mapGetters("$_modules", {
      darkmode: "getDarkMode",
      user: "getUser"
    }),
    ...mapGetters("$_depositStatement", ["getViewTransaction"]),
  },
  created() {
    this.getData();
  },
  methods: {
    _search(){
      this.data.page = 1;
      this.getData()
    },
    async getData() {
      this.fetchHistoryDeposit(this.data);
    },
     currencyFormatKm(num) {
      if(num)return num.toLocaleString('en');
      else return 0;
      // num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      
    },
    currencyFormatEn(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString('en')
    },

    ...mapActions("$_depositStatement", ["fetchHistoryDeposit"]),
    ...mapMutations("$_depositStatement", ["UPDATE_VIEW_DEPOSIT"]),
  },
};
</script>

<style lang="scss" scoped></style>
