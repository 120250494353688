<template>
  <v-app  class="parent-app">
      <!--nav bar-->
     <HeaderComponent></HeaderComponent>
      <!--side bar-->
    <SideBarComponent></SideBarComponent>
<!-- 
    <v-app-bar app clipped-left dense>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-5"
      ></v-app-bar-nav-icon>
      <v-btn
        text
        @click="changeLocale"
        style="font-family: 'khmer mef1' !important"
        class="welcome-title lang-btn"
      >
        {{ $t("lang." + $i18n.locale) }}
      </v-btn> -->

      <!-- <template>
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              style="font-family: 'khmer mef1' !important"
              close-delay="mr-5"
              class="welcome-title"
            >
              <span
                style="font-weight: bold; text-transform: uppercase !important"
                v-if="user && user.role_id != '612a3fcc1a1fcead8871e822'"
              >
                {{ $t("title.welcome")
                }}{{
                  user && user.currency_type === 1
                    ? " " +
                      user.user_name +
                      "(" +
                      currencyFormatUsd(user.balance) +
                      " $ )"
                    : user && user.currency_type === 2
                    ? " " +
                      user.user_name +
                      "(" +
                      currencyFormatKm(user.balance) +
                      " ៛ )"
                    : user.role_id == "612a40c71a1fcead8871e835"
                    ? "  " + user.user_name + " "
                    : user.user_name
                }}</span
              >
              <span
                style="font-weight: bold; text-transform: uppercase !important"
                v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'"
              >
                {{ $t("title.welcome")
                }}{{
                  " " +
                  user.user_name +
                  " (" +
                  currencyFormatUsd(user.companyBalances[0].balance) +
                  "$, " +
                  currencyFormatKm(user.companyBalances[1].balance) +
                  "៛ " +
                  " )"
                }}</span
              >
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center" v-if="user">
                <span
                  class="red darken-3 h4 font-weight-bold text-white px-3"
                  >{{
                    user && !user.enable
                      ? "You has been suspend please contact your upline"
                      : ""
                  }}</span
                >
                <h3
                  style="margin-top: 5px !important; font-family: 'khmer mef1'"
                >
                  {{ $t("title.welcome") }}
                  <span
                    style="
                      color: blue;
                      font-weight: bold;
                      text-transform: uppercase !important;
                    "
                    >{{ user ? user.user_name : "" }}</span
                  >
                </h3>
                <p
                  class="text-caption mt-2"
                  style="
                    margin-bottom: 5px !important;
                    font-family: 'khmer mef1' !important;
                  "
                >
                  {{ $t("title.account_balance") }}:
                  <span
                    style="color: green; font-weight: bold; font-size: 16px"
                  >
                    {{ currencyFormat(user.balance) }}</span
                  >
                </p>
                <p
                  class="text-caption"
                  style="font-family: 'khmer mef1' !important"
                >
                  {{ $t("title.user_role") }}: {{ user.role_name }}
                </p>
                <v-divider class="my-2"></v-divider>
                <v-btn
                  depressed
                  rounded
                  small
                  text
                  to="change-password"
                  style="font-family: 'khmer mef1'"
                >
                  <v-icon>mdi-account-lock</v-icon>
                  {{ $t("button.change_password") }}
                </v-btn>

                <v-btn
                  depressed
                  rounded
                  small
                  text
                  @click="onLogout"
                  style="font-family: 'khmer mef1'"
                >
                  <v-icon>mdi-lock-open-variant</v-icon>
                  {{ $t("button.logout") }}
                </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>
      <v-spacer></v-spacer>
      <span class="red darken-3 h4 font-weight-bold text-white px-3">{{
        user && !user.enable
          ? "You has been suspend please contact your upline"
          : ""
      }}</span>
      <v-spacer></v-spacer> -->

    <div class="main-content" :style="!drawer?'padding:20px 30px' :''">
      <section class="section">
        <!-- add content here -->
        <router-view></router-view>
      </section>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    HeaderComponent: () => import("../components/Header.vue"),
    SideBarComponent:() => import("../components/SideBar.vue")
  },
  data: () => ({
    selectedItem: null,
    lang: ["en", "kh"],
    noTree: [{ text: "ទំព័រដើម", icon: "mdi-home", action: "dashboard" }],
    items: [
      { name: "ប៉ុស្តិ៌ 1", id: 1 },
      { name: "ប៉ុស្តិ៌ 2", id: 2 },
      { name: "ប៉ុស្តិ៌ 3", id: 3 },
      { name: "ប៉ុស្តិ៌ 4", id: 4 },
    ],
    channelType: { name: "1", id: 1 },
    nn : [
    'adfadsf', 'asdasdeqweqwe111'
    ]
  }),
  beforeMount() {
    this.$nextTick(() => {
      if (
        this.$cookies.get("local") !== undefined &&
        this.$cookies.get("local") !== null
      ) {
        this.$i18n.locale = this.$cookies.get("local");
      } else {
        this.$i18n.locale = "kh";
      }
    });
  },
  computed: {
    ...mapGetters("$_modules", {
      menu: "getMenu",
      user: "getUser",
      drawer: "getDrawer",
    }),
  },
  watch: {
    user() {},
  },
  async created() {
    this.connectSocket();
    if (this.$cookies.get("channel-type")) {
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
    }
    if (this.$cookies.get("token")) {
      await this.fetchUser();
      await this.fetchUserAccessMenu();
    }

    if (
      (this.user && this.user.role_id == "612a40c71a1fcead8871e835") ||
      this.user.role_id == "63cddbd5b51c1415b8bae1df" ||
      this.user.role_id == "63cddc81b51c1415b8bae1fd" ||
      this.user.role_id == "63cddccfb51c1415b8bae208" ||
      this.user.role_id == "63cddd30b51c1415b8bae213" ||
      this.user.role_id == "63cddd6fb51c1415b8bae21e" ||
      this.user.role_id == "63cddde1b51c1415b8bae229" ||
      this.user.role_id == "6406b857c2fa980eb0174708" ||
      this.user.role_id == "64095989c2fa980eb0174893" ||
      this.user.role_id == "64643d791ba4b87724c7158b" ||
      this.user.role_id == "64643d1f1ba4b87724c7158a" 
    ) {
      this.$cookies.set("channel-type", this.user.channel_post);
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
    }
  },
  methods: {
    returnString(data){
      return data
    },  
   
    changeLocale() {
      if (this.$i18n.locale === "kh") {
        this.$cookies.set("local", "en");
        this.$i18n.locale = "en";
      } else {
        this.$cookies.set("local", "kh");
        this.$i18n.locale = "kh";
      }
    },
    selectChannelType(channelType) {
      this.$cookies.set("channel-type", channelType);
      this.channelType = {
        name: this.$cookies.get("channel-type"),
        id: parseInt(this.$cookies.get("channel-type")),
      };
      if (
        parseInt(this.$cookies.get("channel-type")) == 2 ||
        parseInt(this.$cookies.get("channel-type")) == 3 ||
        parseInt(this.$cookies.get("channel-type")) == 4
      )
        this.$connectSocket(
          this.$cookies.get("token"),
          this.$cookies.get("channel-type")
        );
      this.$router.go();
    },
    currencyFormat(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    connectSocket() {
      this.$connectSocket(
        this.$cookies.get("user_role"),
        this.$cookies.get("token")
      );
    },
    ...mapActions("$_modules", ["fetchUserAccessMenu", "fetchUser"]),
  },
};
</script>

<style>
.v-application{

    height:100vh;
    position: relative;
    background-position: 55.65% 55%;
    background-image: url('../assets/images/bg_folwer_t.gif') !important;
}
.table-responsive{
  overflow-x:hidden!important;
}
</style>
