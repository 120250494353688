const headers = [
    {
        text: 'No',
        align: 'start',
        value: 'no',
    },
    { text: 'App', value: 'app_id' },
    { text: 'IP Address', value: 'public_ip' },
    { text: 'Date', value: 'date' },
    { text: 'FightNo', value: 'fight_no' },
    { text: 'ChannelType', value: 'channel_type' },
    { text: 'Betting', value: '' },
    { text: 'Result', value: '' },
    { text: 'Amount Turnover', value: 'amount' },
    { text: 'Payout', value: 'payout' },
    { text: 'AmountWin/Lose', value: 'amount_win' },
    { text: 'Cash', value: 'cash' },
];

export default headers;