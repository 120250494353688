<template>
    <div>
      <div class="card">
      <div class="card-body">
        <div class="table-responsive" style="text-align: center">
        <v-data-table :headers="headers" 
                      :items="getUsers ? getUsers.users : []" 
                      :footer-props="footerProps" item-key="_id"
                      class=" table table-striped"
                      id="table-1" 
                      :server-items-length="getUsers ? getUsers.totalRow : null" 
                      style="font-family: 'khmer mef1'"
                      :page.sync="data.page" 
                      :items-per-page.sync="data.rowsPerPage" 
                      :sort-desc.sync="data.descendingFormat"
                      :sort-by.sync="data.sortByFormat" 
                      @update:page="getData" 
                      @update:items-per-page="getData"
                      @update:sort-desc="getData" :loading="getLoading" mobile-breakpoint="0">
                      <!-- <template v-slot:header="{ props }">
                        <th v-for="head in props.headers" :key="head._id">
                          {{ head.text }}
                        </th>
                      </template> -->
            <template v-slot:top>
                <Header :darkmode="darkmode" :params="data" :user-permission="userAccessPermission" />
            </template>

            <template v-slot:[`item`]="{ item }">
                <tr>
                    <td>{{ (getUsers.users.indexOf(item) + ((data.page - 1) * data.rowsPerPage)) + 1 }}</td>
                    <td>{{ item.app_name }}</td>
                    <td>{{ item.com }}</td>
                    <td>{{ item.senior }}</td>
                    <td>{{ item.master }}</td>
                    <td>{{ item.agent }}</td>
                    <td>{{ item.user_name }}</td>
                    <td>{{ item.createdAt }}</td>
                    <td v-if="item.role_id !== '612a3fcc1a1fcead8871e822' && (item.currency_type === 1 || item.currency_type === 3)">
                        {{ currencyFormatUsd(item.balance) }}
                    </td>

                    <td v-if="item.role_id !== '612a3fcc1a1fcead8871e822' && item.currency_type === 2">
                        {{ currencyFormatKh(item.balance) }}
                    </td>
                    <td v-if="item.role_id !== '612a3fcc1a1fcead8871e822' && item.currency_type === 4">
                        {{ currencyFormatKh(item.balance) }}
                    </td>
                    <td>
                        <a @click="_openDialogListIpUser(item)">
                            {{ item.public_ip }}
                        </a>
                    </td>
                    <td class="text-center">
                        <v-btn :color="item.enable ? 'success' : 'error'" @click="_openDialog(item)">
                            {{
 item.enable ?
                "Enabled" : "Not Enable"
                            }}
                        </v-btn>
                    </td>
                    <td class="text-center">
                        <v-btn :color="!item.is_suspend ? 'success' : 'error'" @click="_openDialogs(item)">
                            {{
 !item.is_suspend ?
                "Active" : "suspend"
                            }}
                        </v-btn>
                    </td>
                    <td class="text-center">
                        <v-btn color="primary" @click="_openConfigUser(item)">
                            <v-icon style="color:white; font-size:30px">
                                mdi-account-cog-outline
                            </v-icon>
                        </v-btn>

                    </td>
                    <td class="text-center">
                        <v-btn color="green" @click="_openTransaction(item)">
                            <v-icon style="color:white; font-size:30px">
                                mdi-account-cash-outline
                            </v-icon>
                        </v-btn>
                    </td>
                    <td class="text-center">
                        <v-btn color="warning" @click="_openDetailBetting(item)">
                            <v-icon style="color:white; font-size:30px">
                                mdi-book-account
                            </v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <!-- Confirm Dialog -->
        <v-dialog v-model="dialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h5 py-5">
                    {{ player.enable ? 'បិទដំណើរការ Player : ' + player.user_name : 'បើកដំណើរការ Player : ' + player.user_name }}
                </v-card-title>
                <v-card-actions>
                    <v-btn color="blue-grey" class="ma-2 white--text" @click="_closeDialog">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2 white--text" :color="player.enable ? 'error' : 'success'" @click="_takeAction">
                        {{ this.player.enable ? "Inactive this Player" : "Active this Player" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogs" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h5 py-5">
                    {{ player.suspend ? 'បិទដំណើរការ Player : ' + player.user_name : 'បើកដំណើរការ Player : ' + player.user_name }}
                </v-card-title>
                <v-card-actions>

                    <v-btn color="blue-grey" class="ma-2 white--text" @click="_closeDialogs">Cancel 1</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="ma-2 white--text" :color="player.is_suspend ? 'error' : 'success'" @click="_takeActions">
                        {{ this.player.is_suspend ? "Suspend this Player" : "Active this Player" }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DialogListIpUserComponents :dialog="ipDialog" :listData="listIpUserData" @onCloseDialog="ipDialog = !ipDialog" />
        <DialogConfigUser :darkmode="darkmode" :dialogConfig="dialogConfig" :dataConfig="configUserData" :loading="loading"
                          :passLimitConfig="getLimitData" 
                          v-on:submit="onChangeConfigUser" 
                          :passPlayer="selectedPlayer"
                          v-on:onCloseDialog="closeConfigUserDialog"
                          v-on:changeTab="onChangeTab"
                          />
        <dialog-view-transaction v-if="dialogViewTransaction"
                                 :dialog-withdraw="dialogViewTransaction"
                                 :darkmode="darkmode"
                                 :dialog="dialogViewTransaction"
                                 :selectedUser="selectedUser"
                                 v-on:onCloseDialog="_closeDialogViewTransaction" />
        <v-dialog :value="dialogDetailBetting"
                  max-width="90%"
                  persistent
                  transition="dialog-bottom-transition" style="z-index: 99999 !important">
            <v-card>
                <v-toolbar>
                    <v-btn icon @click="dialogDetailBetting=false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row class="pl-5">
                        <v-col cols="6" sm="6" md="12" class="text-left mt-4">
                            <v-toolbar-title>Detail Player Statement</v-toolbar-title>
                        </v-col>
                        <v-col cols="6" sm="6" md="1" class="text-left mt-4">
                          
                        </v-col>
                        <!-- <v-col cols="12" sm="12" md="1" class="text-left mt-4">
                            <v-row>
                                <v-checkbox v-model="statusTime" value="true" style="margin-top:10px;" class="ml-5"></v-checkbox>
                                <span class="mt-4 mr-2">Time</span>
                            </v-row>
                        </v-col> -->
                        <v-col cols="6" sm="6" md="2" :class="statusTime?'text-right custom-menu' : 'text-right'">
                            <v-menu v-model="v_startDate" :close-on-content-click="false" :nudge-right="100" transition="scale-transition"
                                    offset-y min-width="auto" class="pl-5">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="startDate" label="ចាប់ផ្តើម" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                                                  v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="startDate" @input="v_startDate = false">
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col v-if="statusTime" cols="4" sm="4" md="1" class="text-right pl-0 ml-0 mb-5 pt-5">
                            <input v-model="v_startTime" type="time" class="custom-input-time" />
                        </v-col>
                        <v-col cols="6" sm="6" md="2" :class="statusTime?'text-right custom-menu' : 'text-right'">
                            <v-menu v-model="v_endDate" :close-on-content-click="false" :nudge-right="100" transition="scale-transition"
                                    offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="endDate" label="បញ្ចប់" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="endDate" @input="v_endDate = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col v-if="statusTime" cols="4" sm="4" md="1" class="text-right pl-0 ml-0 mb-5 pt-5">
                            <input v-model="v_endTime" type="time" class="custom-input-time" />
                        </v-col>
                        <v-col cols="6" sm="6" md="2" class="text-left mt-4">
                            <v-select v-model="defaultSelected"
                                      :items="selectChannel"
                                      item-text="name"
                                      item-value="id"
                                      style="margin-top:-10px"
                                        single-line></v-select>
                        </v-col>
                        <v-col cols="8" sm="8" md="2" class="text-right">
                            <v-text-field v-model="v_search"
                                          append-icon="mdi-magnify"
                                          label="Search ( Fight no, គូ ប្រកួត )"
                                          single-line
                                          hide-details
                                          clearable
                                          class="mr-5" />
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="4" sm="4" md="1" class="text-right mr-2 mb-5">
                            <v-btn class="white--text btn-custom mt-3"
                                   color="green darken-1"
                                   depressed
                                   @click="searchq">
                                <!-- @click="fetchListStatement(params)" -->
                                SEARCH
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table
                                  class="elevation-1"
                                  item-key="date"
                                  :headers="headersDetail"
                                  :footer-props="footerProps"
                                  :items="detailData ? detailData : []"
                                  :server-items-length="detailDataCount ? parseInt(detailDataCount.count): null"
                                  :sort-desc.sync="dataRequest.descendingFormat"
                                  :sort-by.sync="dataRequest.sortByFormat"
                                  :page.sync="dataRequest.page"
                                  :items-per-page.sync="dataRequest.rowsPerPage"
                                  @update:page="updatePage"
                                  @update:items-per-page="updatePage"
                                  @update:sort-desc="updatePage"
                                  :loading="getLoadingDetail"
                                  mobile-breakpoint="0">
                        <template v-slot:[`item`]="{ item }">
                            <tr>
                              <td>{{ detailData ? (detailData.indexOf(item) + ( (dataRequest.page -1 ) *  dataRequest.rowsPerPage)) + 1 :"loading ..." }}</td>
                              <td>{{ item.app_id }}</td>
                              <td>{{ item.public_ip }}</td>
                              <td>{{ item.date }}</td>
                              <td>{{ item.fight_no }}</td>
                              <td>
                                <span v-if="item.channel_type == 90"> Lotto </span>
                                <span v-else-if="item.channel_type == 91"> Yuki </span>
                                <span v-else-if="item.channel_type == 92"> PP78 </span>
                                <span v-else-if="item.channel_type == 1"> CO1 </span>
                                <span v-else-if="item.channel_type == 2"> CO2 </span>
                                <span v-else-if="item.channel_type == 3"> CO3 </span>
                                <span v-else-if="item.channel_type == 4"> CO4 </span>
                                <span v-else-if="item.channel_type == 15"> CO5 </span>
                                <span v-else-if="item.channel_type == 16"> CO6 </span>
                                <span v-else-if="item.channel_type == 5"> KL1 </span>
                                <span v-else-if="item.channel_type == 6"> BA1 </span>
                                <span v-else-if="item.channel_type == 7"> BA2 </span>
                                <span v-else-if="item.channel_type == 18"> BA3 </span>
                                <span v-else-if="item.channel_type == 8"> TD1 </span>
                                <span v-else-if="item.channel_type == 9"> TD2 </span>
                                <span v-else-if="item.channel_type == 19"> TD3 </span>
                                <span v-else-if="item.channel_type == 10"> LO1 </span>
                                <span v-else-if="item.channel_type == 11"> YU1 </span>
                                <span v-else-if="item.channel_type == 12"> AP1 </span>
                                <span v-else-if="item.channel_type == 17"> AP2 </span>
                                <span v-else-if="item.channel_type == 13"> FT1 </span>
                                <span v-else-if="item.channel_type == 14"> TS1 </span>
                              </td>
                              <td>
                                <span v-if="item.channel_type == 11">
                                  {{ item.type_of_betting.desc }}
                                </span>
                                <span v-else>
                                  {{ item.type_of_betting.name }}
                                </span>
                                
                              </td>
                              <td>
                                <span v-if="item.channel_type == 10">
                                  {{ item.result1.name }}, {{ item.result2.name }}
                                </span>
                                <span v-if="item.channel_type == 6 || item.channel_type == 7">
                                  <span>{{ item.result1.name }}</span> <span v-if="item.result3.name">, {{ item.result3.name }} </span>
                                </span>
                                <span v-if="item.channel_type == 12">
                                  {{ item.result1.name }}
                                </span>
                                <span v-else>
                                  {{ item.result1.desc }}
                                </span>
                              </td>
                              <td class="amount-bet-color">
                                <!-- {{ dataRequest && dataRequest.currencyType == 2 ? currencyFormatKm(item.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 ?  currencyFormatEn(item.amount) :"Loading ..." }} -->
                                {{currencyFormatEn(item.amount)}}
                              </td>
                              <td  >{{ item.payout }}</td>
                              <td :class="item.amount_win < 0 ? 'lose-color font-weight-bold' :'win-color font-weight-bold'">{{item.amount_win ? currencyFormatEn(item.amount_win) : 0 }}</td>
                              <td>{{item.cast ?  currencyFormatEn(item.cast) : 0}}</td>
                            </tr>
                        </template>
                        <template slot="body.append">
                            <td :colspan="8" class="text-xs-center pl-4 py-2 ">
                                {{ $t("title.total") }}
                            </td>
                            <td :class=" detailDataCount.amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">

                                {{ detailDataCount ? currencyFormatEn(detailDataCount.amount) : "loading ..."}}
                            </td>
                            <td></td>
                            <td :class=" detailDataCount.amount_win  < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
                                <!-- 123 -->
                                <!-- {{ dataRequest && dataRequest.currencyType == 2 && detailDataCount ? currencyFormatKm(detailDataCount.amount) : dataRequest.currencyType && dataRequest.currencyType != 2 && detailDataCount ?  currencyFormatEn(detailDataCount.amount) :"Loading ..." }} -->
                                {{ detailDataCount ? currencyFormatEn(detailDataCount.amount_win) : "loading ..."}}
                            </td>

                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- <div class="text-center pt-2">
          <v-pagination
            v-model="data.page"
            :length="
              getUsers
                ? getUsers.totalRow % data.rowsPerPage != 0
                  ? getUsers.totalRow / data.rowsPerPage + 1
                  : getUsers.totalRow / data.rowsPerPage
                : 0
            "
          ></v-pagination>
          <v-text-field
            :value="data.rowsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="data.rowsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div> -->
    </div>
  </div>
  </div>
  
</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
    import headersDetail from "./_api/columns_detail";
    import moment from "moment";
    import Vue from "vue";
export default {
  
  components: {
        Header: () => import("./_components/HeaderTable.vue"),
    DialogListIpUserComponents: () => import("./_components/DialogListIpUser"),
    DialogConfigUser: () => import("./_components/ConfigUserData"),
    DialogViewTransaction : () => import("./_components/DialogViewTransaction"),
  },
  data: () => ({
    valid: true,
    search: null,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    configUserData: {},
    data: {
      page: 1,
      rowsPerPage: 10,
      search: "",
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
    },
    dialog: false,
    dialogs: false,
    dialogConfig: false,
    player: [],
    ipDialog: false,
    listIpUserData: [],
    onCloseDialog: false,
    passLimitConfig: {},
    loading: false,
    selectedPlayer: {},
    selectedUser:{},
      dialogViewTransaction: false,
      dialogDetailBetting: false,
      detailData: [],
      detailDataCount: [],
      headersDetail: headersDetail,
      dataRequest: {
          descendingFormat: [true],
          sortByFormat: ["amount_win"],
          descending: true,
          sortBy: null,
          page: 1,
          rowsPerPage: 10,
          fields: ["co_type_of_betting.name", "fight_no"],
          search: "",
          channelType: 0,
          startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
          endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      },
      getLoadingDetail: false,
      user_iid: null,
      channel_type: null,
      v_search: "",
      defaultSelected: {
          id: 0,
          name: "All Channel",
      },
      selectChannel: [
          {
              id: 0,
              name: "All Channel",
          },
          {
              id: 1,
              name: "CO1",
          },
          {
              id: 2,
              name: "CO2",
          },
          {
              id: 3,
              name: "CO3",
          },
          {
              id: 4,
              name: "CO4",
          },
          {
              id: 5,
              name: "KL1",
          },
          {
              id: 6,
              name: "BA1",
          },
          {
              id: 7,
              name: "BA2",
          },
          {
              id: 18,
              name: "BA3",
          },
          {
              id: 8,
              name: "TD1",
          },
          {
              id: 9,
              name: "TD2",
          },
          {
              id: 19,
              name: "TD3",
          },
          {
              id: 10,
              name: "LO1",
          },
          {
              id: 11,
              name: "YU1",
          },
          {
              id: 12,
              name: "AP1",
          },
          {
              id: 13,
              name: "FT1",
          },
          {
              id: 14,
              name: "TS1",
          },
      ],

      v_startTime: "00:00",
      v_endTime: "23:59",
      statusTime: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: this.$t("header.no"),
          align: "center",
          sortable: false,
        },
        { text: this.$t("header.app_name"), value: "app_name", align: "center"},
        { text: 'Company', value: "com", align: "center"},
        { text: 'Senior', value: "senior", align: "center"},
        { text: 'Master', value: "master", align: "center"},
        { text: 'Agent', value: "agent", align: "center"},
        {
          text: this.$t("header.user_name"),
          align: "center",
          value: "user_name",
          sortable: true,
        },
        {
          text: this.$t("header.createdAt"),
          align: "center",
          value: "createdAt",
          sortable: true,
        },
        { text: this.$t("header.balance"), value: "balance", align: "center"},
        { text: this.$t("header.public_ip"), value: "public_ip", align: "center"},
        { text: this.$t("header.active"), value: "enable",sortable: false, align: "center"},
        { text: this.$t("header.suspend"), value: "suspend",sortable: false,  align: "center"},
        { text: this.$t("header.configUser"), value: "",   align: "center",sortable: false, },
        { text: this.$t("header.reportDW"), value: "",   align: "center",sortable: false, },
        { text: this.$t("header.reportBetting"), value: "",  align: "center",sortable: false, },
      ];
    },
    ...mapGetters("$_modules", { darkmode: "getDarkMode", userp: "getUser" }),
    ...mapGetters("$_enableDisablePlayer", [
      "getUsers", "getLoading", "getLimitData"
    ]),
  },
  async created() {
    await this.getData();
  },
  watch: {
    getUsers: function () { },
    configUserData: function () { },
  },
  methods: {
    _openDialogListIpUser(item) {
      this.player = item;
      this.getListIpUserData(item._id);
      this.ipDialog = true;
    },
    async getListIpUserData(_id) {
      let data = {
        user_id: _id
      }
      await this.$request
        .post({
          url: "/users/getLoginIpByUser",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.listIpUserData = res.data.data
            // this.waiting = false;
          }
        });
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      // return parseInt(num).toLocaleString("en");
      },
      currencyFormatEn(num) {
          num = parseFloat(num);
          return num.toLocaleString("en");
      },
    async getData() {
      await this.fetchListUsers(this.data)
    },
    _closeDialog() {
      this.player = [];
      this.dialog = false;
    },
    _closeDialogs() {
      this.player = [];
      this.dialogs = false;
    },
    _openDialog(player) {
      this.player = player;
      this.dialog = true;
    },

    _openDialogs(player) {
      this.player = player;
      this.dialogs = true;
    },
    async _takeAction() {
      if (this.player) {
        let data = {
          player_id: this.player._id,
          enable: !this.player.enable
        }
        await this.updateUser({ vm: this, data: data })
        this.dialog = false;
        this.getData()
      }

    },
    async _takeActions() {
      if (this.player) {
        let data = {
          player_id: this.player._id,
          is_suspend: !this.player.is_suspend
        }
        // console.log("okay")
        await this.updateSuspendUser({ vm: this, data: data })
        this.dialogs = false;
        this.getData()
      }

    },
    //
    async _openConfigUser(player) {
      await this.upConfigUser(player)
      this.selectedPlayer = player;
      this.dialogConfig = true;
    },
    async onChangeConfigUser(payload) {
      this.loading = true;
      let dataLimit = this.configUserData.filter(channel => channel.channel_type == payload.channel_type);
      let data;
      if (dataLimit.length > 0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
          is_controller: payload.is_controller
        }
      }else{
        data = this.configUserData;
      }
      if (data._id) {
        await this.updateConfigUser({ vm: this, d: data });
        this.loading = false;
        // this.dialogConfigUserData = false;
      }
    },
    async upConfigUser(player) {
      let data = { currency_type: player.currency_type, _id: player._id };
      this.$request
        .post({
          url: "configUser/getConfigData",
          data: data,
        })
        .then((res) => {
          this.configUserData = res.data.data.object;
          
        });
        this.channel_type = this.configUserData[0] ?this.configUserData[0].channel_type : 1;
      await this.getLimitDataFromApi(player, this.channel_type);
    },
    async getLimitDataFromApi(player, channel_type) {

      let submitLimitData = {
        currency_type: player.currency_type,
        _id: player.parent_id,
        channel_type: channel_type
      };
      await this.requestLimitData(submitLimitData);
    },
    closeConfigUserDialog() {
      this.dialogConfig = false;
    },
    async onChangeTab(channel_type){
      let submitLimitData = {
        currency_type: this.selectedPlayer.currency_type,
        _id: this.selectedPlayer.parent_id,
        channel_type: channel_type
      };
      await this.requestLimitData(submitLimitData);
    },
    _openTransaction(selectedUser){
      this.selectedUser = selectedUser;
      this.dialogViewTransaction = true;
    },
    _closeDialogViewTransaction(){
      this.selectedUser = {};
      this.dialogViewTransaction = false;
      },
      _openDetailBetting(user) {
          this.user_iid = user._id;
          this.loadDetailData(this.user_iid);
          this.dialogDetailBetting = true;
          Vue.$cookies.set("currency-type", user.currency_type);
      },
      searchq() {
          if (this.defaultSelected && this.defaultSelected._id) this.channel_type = this.defaultSelected._id;
          else this.channel_type = this.defaultSelected;
          this.dataRequest.search = this.v_search;
          this.loadDetailData(this.user_iid)
      },
      updatePage() {
          this.getLoadingDetail = true;
          this.loadDetailData(this.user_iid);
          this.getLoadingDetail = false;
      },
      async loadDetailData(_id) {
          this.detailData = [],
              this.detailDataCount = [],
              this.getLoadingDetail = true;
          if (this.channel_type != null && typeof this.channel_type === 'object') {
              this.dataRequest.channelType = this.channel_type.id;
          }
          if (this.channel_type != null && typeof this.channel_type !== 'object') {
              this.dataRequest.channelType = this.channel_type;
          }
          this.startTime = this.v_startTime;
          this.endTime = this.v_endTime;
          if (this.v_endDate && this.v_startDate) {
              this.startDate = this.v_startTime;
              this.endDate = this.v_endDate;
          }

          let formatStartDate = '';
          let formatEndDate = '';

          if (this.statusTime) {
              formatStartDate = this.startDate + "T" + this.v_startTime + ":00";
              formatEndDate = this.endDate + "T" + this.v_endTime + ":59";
          } else {
              formatStartDate = this.startDate+"T00:00:01";
              formatEndDate = this.endDate+"T23:59:00";
          }
          this.dataRequest.search = this.v_search;
          this.dataRequest.startDate = formatStartDate;
          this.dataRequest.endDate = formatEndDate;
          this.dataRequest.sortByFormat.length < 1
              ? (this.dataRequest.sortBy = "_id._id")
              : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
          this.dataRequest.descendingFormat.length > 0
              ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
              : (this.dataRequest.descending = true);
          await this.$request
              .post({
                  url: "statementCurrency/playerDetail/" + _id,
                  data: this.dataRequest,
              })
              .then((res) => {
                  if (res.data.code) {

                      this.detailData = res.data.data.object;
                      this.detailDataCount = res.data.data.objectCount;
                      this.getLoadingDetail = false;
                  } else {

                      this.getLoadingDetail = false;
                  }
              });
      },

      currencyFormat(num) {
          num = parseFloat(num);
          // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          return parseInt(num).toLocaleString('en')
      },
    ...mapMutations("$_enableDisablePlayer", [
      "UPDATE_CURRENT_USER",
      "UPDATE_LIMIT_DATA",
      "UPDATE_LOADING_CONFIG"
    ]),
    ...mapActions("$_enableDisablePlayer", [
      "updateUser",
      "updateSuspendUser",
      "fetchListUsers",
      "requestLimitData",
      "updateConfigUser"
    ]),
  },

};
</script>

<style lang="scss" scoped>
.users {
  width: 50%;
  float: left;

  h3 {
    font-size: 17px;
  }
}

table {
  font-family: "khmer mef1" !important;
}

    .custom-menu {
        max-width: 11%;
    }

    .custom-input-time {
        border: 1.5px solid black;
        padding: 2px 10px;
        border-radius: 5px;
        margin-top: 10px;
    }
    .lose-color{
        color:red;
    }
    .win-color{
        color:dodgerblue;
    }
    .WALA{
        color:deepskyblue;
    }
    .MERON{
        color:red;
    }
    .TIE{
        color:forestgreen;
    }
    .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px !important;
}
</style>
